import React from 'react';
import styled from 'styled-components';
import {Modal, Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';

function YouTubeModal(props) {
  const {video, onClose} = props;

  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={1000}
      visible={video}
      destroyOnClose={true}
      onOk={() => {
        onClose();
      }}
      onCancel={() => {
        onClose();
      }}>
      <ModalContent {...props} />
    </Modal>
  );
}

function ModalContent(props) {
  const {video, onClose} = props;
  const [user] = useOutlet('user');

  console.log('DBG', video, user);

  React.useEffect(() => {
    async function fetchData() {
      if (video && user) {
        const liveVideo = await JStorage.fetchOneDocument('video', {
          id: video.id,
        });
        const cnt = (liveVideo.access || {})[user.sub] || 0;

        await JStorage.updateDocument(
          'video',
          {
            id: video.id,
          },
          {
            [`access.${user.sub}`]: cnt + 1,
          },
        );
      }
    }

    fetchData();
  }, [video, user]);

  return (
    <Wrapper>
      <Center>
        <h2>{video && video.title}</h2>
        {video && (
          <iframe
            title={video.title}
            width="900"
            height="500"
            frameborder="0"
            allowfullscreen
            src={`https://player.vimeo.com/video/${video.vid}`}
            // src={`https://www.youtube.com/embed/${video.vid}?rel=0&modestbranding=1&fs=0&showinfo=0`}
          ></iframe>
        )}
        <p>{video && video.description}</p>
        <Button onClick={onClose}>Close</Button>
      </Center>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
`;

const Center = styled.div`
  /* max-width: 620px; */
  margin: 0 auto;
`;

export default YouTubeModal;
